* {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Courier New", Courier, monospace;
  color: white;
  /*background: linear-gradient(
    90deg,
    rgb(59, 157, 164) 25%,
    rgb(16, 143, 152) 100%
  );*/
  background: linear-gradient(
    90deg,
    rgba(0, 36, 78, 1) 30%,
    rgb(3, 0, 60) 100%
  );
}

.BGNav {
  background: linear-gradient(
    90deg,
    rgba(3, 0, 60) 15%,
    rgb(0, 36, 78, 1) 100%
  );
}
.quotes-right {
  margin-left: auto;
}

/* Cards for Home page */

.homeCard {
  position: relative;
  height: 240px;
  width: 280px;
  background: linear-gradient(
    90deg,
    rgba(3, 0, 60) 25%,
    rgb(0, 36, 78, 1) 100%
  );
}
.imgBx {
  position: relative;
  height: 130px;
  width: 130px;
  left: 50%;
  top: -60px;
  transform: translateX(-50%);
  overflow: hidden;
  background: linear-gradient(
    90deg,
    rgba(3, 0, 60) 25%,
    rgb(0, 36, 78, 1) 100%
  );
}
.imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Cards for Products page */
.icone-size {
  font-size: 1rem !important;
}
.infosProductCard {
  position: relative;
  height: fit-content;
  width: fit-content;
  max-height: fit-content;
  max-width: 800px;
  min-width: 320px;
  background: linear-gradient(
    90deg,
    rgba(3, 0, 60) 25%,
    rgb(0, 36, 78, 1) 100%
  );
}

.imgProductCard {
  position: relative;
  height: 130px;
  align-items: center;
  top: auto;
  overflow: hidden;
}
.imgProductCard img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 825px) {
  .infosProductCard {
    width: fit-content;
  }
}
@media (max-width: 400px) {
  .infosProductCard {
    width: 280px !important;
  }
}
.titleProductCardBlue{
  color: rgb(71, 246, 255);
}
.formBG {
  background: linear-gradient(90deg);
}
/* Anime venant du dessus */
.animate-slide-from-top {
  animation-name: slide-from-top;
  animation-duration: 4.5s;
  animation-fill-mode: forwards;
}

@keyframes slide-from-top {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* Anime venant de gauche */
.animate-slide-from-left {
  animation-name: slide-from-left;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

@keyframes slide-from-left {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
/*Anime venant de droite */
.animate-slide-from-right {
  animation-name: slide-from-right;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

@keyframes slide-from-right {
  from {
    opacity: 0;
    transform: translateX(+100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
